export default {
  home: {
    title: 'Home',
    path: '/',
    children: {
      'sign-up-testpanel': {
        title: 'Sign Up Tespanel',
        path: '/sign-up-testpanel',
      },
      'sj-testpanel': {
        title: 'Sexjobs Tespanel',
        path: '/sj-testpanel',
      },
      'privacy-policy': {
        title: 'Privacy & Policy',
        path: '/privacy-policy',
      },
      'about-us': {
        title: 'About Us',
        path: '/about-us',
      },
      'cookie-statement': {
        title: 'Cookie statement',
        path: '/cookie-statement',
      },
      'terms-condition': {
        title: 'Terms & Condition',
        path: '/terms-condition',
      },
      'friends-of': {
        title: 'Friends Of',
        path: '/friends-of',
      },
      'code-of-conduct': {
        title: 'Code of Conduct & Disclaimer',
        path: '/code-of-conduct',
      },
      'safety-information': {
        title: 'Safety and Information',
        path: '/safety-information',
      },
      promotion: {
        title: 'Promotion on Sexjobs',
        path: '/promotion',
      },
      'my-sexjobs': {
        title: 'profile_navigation_title',
        path: '/my-sexjobs',
        children: {
          delete: {
            title: 'Delete account',
            path: '/my-sexjobs/account/delete',
          },
          accountSettings: {
            title: 'profile_account_title',
            path: '/my-sexjobs/account/settings',
            children: {
              changeEmail: {
                title: 'Email',
                path: '/my-sexjobs/account/settings/edit/email',
              },
              changePassword: {
                title: 'Password',
                path: '/my-sexjobs/account/settings/edit/password',
              },
              forgotPassword: {
                title: 'Forgot password',
                path: '/my-sexjobs/account/settings/edit/forgot-password',
              },
              changeBillingInfo: {
                title: 'Billing information',
                path: '/my-sexjobs/account/settings/edit/billing-info',
              },
            },
          },
          ads: {
            title: 'profile_details_ads',
            path: '/my-sexjobs/ads',
            children: {
              create: {
                title: 'create_ad',
                path: '/my-sexjobs/ads/create',
              },
              edit: {
                title: 'edit_ad_title',
                path: '/my-sexjobs/ads/edit/:id',
              },
              view: {
                title: 'View Ad',
                path: '/my-sexjobs/ads/view/:id',
              },
              promote: {
                title: 'promote_ad',
                path: '/my-sexjobs/ads/promote/:id',
                children: {
                  update: {
                    title: 'Update Ad',
                    path: '/my-sexjobs/ads/promote/update/:id',
                  },
                },
              },
            },
          },
          profile: {
            title: 'profile_single_title',
            path: '/my-sexjobs/profile',
            children: {
              create: {
                title: 'Create',
                path: '/my-sexjobs/profile/create',
              },
              view: {
                title: 'view',
                path: '/my-sexjobs/profile/view',
              },
              edit: {
                title: 'ads_actions_edit',
                path: '/my-sexjobs/profile/edit',
              },
            },
          },
          billings: {
            title: 'billings',
            path: '/my-sexjobs/billings',
          },
          cart: {
            title: 'cart',
            path: '/my-sexjobs/cart',
          },
          credits: {
            title: 'profile_details_credits',
            path: '/my-sexjobs/credits',
            children: {
              'credits-history': {
                title: 'history',
                path: '/my-sexjobs/creditsHistory',
              },
            },
          },
          notifications: {
            title: 'profile_notifications_title',
            path: '/my-sexjobs/notifications',
            children: {
              settings: {
                title: 'Notifications settings',
                path: '/my-sexjobs/notifications/settings',
              },
            },
          },
          chat: {
            title: 'Chat',
            path: '/my-sexjobs/chat',
            children: {
              settings: {
                title: 'Settings',
                path: '/my-sexjobs/chat/settings',
              },
            },
          },
        },
      },
      category: {
        title: 'Category',
        path: '/:category',
        children: {
          view: {
            title: 'Category View',
            path: '/:category/view/:id',
          },
        },
      },
    },
  },
};

import { useCallback, useLayoutEffect, useRef, useState, memo } from 'react';

import { Image } from 'Components/Shared/UI';

import useStyles from './styles';

const ImageSliderBadge = ({ src, isEvent }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [rowTranslateX, setRowTranslateX] = useState(0);
  const [disableBtns, setDisableBtns] = useState({
    prev: true,
    next: false,
  });

  const sliderContainerRef = useRef();

  const styles = useStyles({
    translateX: `translateX(-${rowTranslateX}px)`,
  });

  const slideTo = useCallback(
    (dir, event) => {
      event.preventDefault();
      event.stopPropagation();
      if (dir === 'next') {
        const index =
          slideIndex === src.length - 1 ? src.length - 1 : slideIndex + 1;
        setSlideIndex(index);
      } else {
        setSlideIndex(slideIndex <= 0 ? 0 : slideIndex - 1);
      }
    },
    [src, slideIndex],
  );

  useLayoutEffect(() => {
    const containerWidth =
      sliderContainerRef.current.getBoundingClientRect().width;
    setRowTranslateX(slideIndex * containerWidth);
    setDisableBtns({
      prev: slideIndex === 0,
      next: slideIndex === src.length - 1,
    });
  }, [slideIndex, src]);

  return (
    <div className={styles['slider-container']} ref={sliderContainerRef}>
      <div className={isEvent ? 'event_slider_badge_row' : 'slider_badge_row'}>
        {src.map((image, index) => (
          <Image
            src={image}
            key={`${image}-${index}`}
            defaultSrc="/images/icons/upload.png"
          />
        ))}
      </div>
      {src.length > 1 ? (
        <>
          <span
            className="lnr lnr-chevron-left sj-image-tile--slider_prev-btn"
            disabled={disableBtns.prev}
            onClick={e => slideTo('prev', e)}
          ></span>
          <span
            className="lnr lnr-chevron-right sj-image-tile--slider_next-btn"
            disabled={disableBtns.next}
            onClick={e => slideTo('next', e)}
          ></span>
        </>
      ) : (
        ''
      )}
    </div>
  );
};
ImageSliderBadge.defaultProps = {
  src: [],
  isEvent: false,
};

export default memo(ImageSliderBadge);
